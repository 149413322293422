import React from "react"
import {graphql} from "gatsby"

import imgPage from "../images/headers/Logos_Ale_FBO_Management.svg"
import Layout from "../components/layout"
import SEO from "../components/seo"
import style from "./fbo-management.module.scss"
import Header from "../components/header";

import Frecuencia from "../images/icons/icon_frecuencia.svg"
//import MembershipLogo from "../images/icons/ale_membership.svg"
import AleLogo from "../images/icons/footer_logo.svg"
import Matriculas from "../images/icons/icon_matriculada.svg"
import Calendar from "../images/icons/icon_calendario.svg"
import Juridico from "../images/icons/icon_juridico.svg"
import Img from "gatsby-image";
import {ParallaxBanner} from "react-scroll-parallax";
import Form from "../components/form";

export default ({data}) => {
    const extraInfo =
        [
            {name: '', mail: 'info@ale.mx', phone: '55 4202 0920'}
        ];
    return (
        <Layout extraInfo={extraInfo}>
            <Header LogoPage={imgPage}/>
            <SEO title="FBO & Management Aerolineas Ejecutivas"/>

            <div className={style.fboCover}>
                <Img fluid={data.portada.childImageSharp.fluid}/>
            </div>
            <Form options={['FBO: Aircraft Management/Hangar']}/>

            <div className={style.fboInfo}>
                <h1>FBO & Management</h1>
                <p>En Ale FBO & Management nos encargamos de la administración y custodia de su aeronave, somos el
                    primer FBO de aviación privada que cuenta con sala internacional en México, donde podrá hacer sus
                    llegadas internacionales y servicio de aduana directamente en nuestras instalaciones.</p>
            </div>

            <div className={style.fboMembership}>
                <div className={style.top}>
                    <div className={style.fboMembershipLogo}>
                        <AleLogo/>
                        <h2>Membership</h2>
                    </div>
                </div>
                <div className={style.bottom}>
                    <div className={style.left}>
                        <p><b>¿Qué es la membresía ALE?</b></p>
                        <p>Es un producto exclusivo diseñado específicamente para brindar soluciones integrales a los
                            propietarios de aeronaves cuya operación se basa en viajes de negocio y placer.</p>
                    </div>
                    <div className={style.right}>
                        <p><b>¿Cómo funciona la membresía?</b></p>
                        <p>Se establece una cuota de administración mensual, por medio de la cual nuestro equipo de
                            profesionales se encarga de llevar el control y seguimiento de todas las necesidades
                            administrativas, operacionales y de mantenimiento de su aeronave, para que usted pueda
                            enfocarse
                            en sus negocios mientras disfruta de viajar sin preocupaciones.</p>
                    </div>
                </div>
            </div>

            <div className={style.fboServices}>
                <h2>Nuestros Servicios</h2>
                <div className={style.top}>
                    <p><b>En ALE FBO & Management nos encargamos de la administración y custodia de su aeronave</b></p>
                    <p>Se establece una cuota de administración mensual, por medio de la cual nuestro equipo de
                        profesionales se encarga de llevar el control y seguimiento de todas las necesidades
                        administrativas, operacionales y de mantenimiento de su aeronave, para que usted pueda enfocarse
                        en sus negocios mientras disfruta de viajar sin preocupaciones.</p>
                </div>
                <div className={style.bottom}>
                    <div className={style.left}>
                        <Frecuencia/>
                        <p><b>Frecuencia</b></p>
                        <p>131.7 en MTY / TLC</p>
                    </div>
                    <div className={style.right}>
                        <p><b>Contamos con sala internacional</b></p>
                        <p>¡Ahora sus viajes internacionales serán más rápidos!</p>
                        <p>Somos el primer FBO de aviación privada que cuenta con sala internacional en
                            México.</p>
                        <p>Nuestros beneficios incluyen:</p>
                        <ul>
                            <li><p><b>Aduana</b></p></li>
                            <li><p><b>Migración</b></p></li>
                            <li><p><b>Sagarpa</b></p></li>
                            <li><p><b>Guardia Nacional</b></p></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className={style.fboImages}>
                <ParallaxBanner
                    className={style.fotoParallax}
                    layers={[{image: data.foto1.publicURL, amount: 1.0}]}
                    style={{maxHeight: '400px'}}
                >
                </ParallaxBanner>
                <div className={style.title}>
                    <p>Servicios de tráfico y despacho</p>
                    <span> </span>
                </div>
                <div className={style.infoBlock}>
                    <div className={style.info}>
                        <ul>
                            <li><p>Atención de salidas y llegadas nacionales e internacionales en Toluca y
                                Monterrey.</p>
                            </li>
                            <li><p>Reservaciones de Hoteles y autos.</p></li>
                            <li><p>Coordinación de migración y aduanas.</p></li>
                            <li><p>Información Meteorológica.</p></li>
                            <li><p>Coordinación de planes de vuelo.</p></li>
                            <li><p>Cartas de aeropuertos (Jeppesen).</p></li>
                        </ul>
                    </div>
                    <Img className={style.img} fluid={data.foto2.childImageSharp.fluid}/>
                </div>
                <div className={style.title}>
                    <p>Servicios de Rampa</p>
                    <span> </span>
                </div>
                <div className={style.infoBlock}>
                    <Img className={style.img} fluid={data.foto3.childImageSharp.fluid}/>
                    <div className={style.info}>
                        <ul>
                            <li><p>Arranques con planta auxiliar.</p></li>
                            <li><p>Comisariato básicos (Café. hielo y periódicos).</p></li>
                            <li><p>Limpiezas interiores y exteriores de avión.</p></li>
                            <li><p>Remolque desde y hacia el hangar.</p></li>
                            <li><p>Servicio de combustible.</p></li>
                            <li><p>Servicio de comisariato extraordinario.</p></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className={style.fboAOC}>
                <h2>Inclusión al aoc</h2>
                <p><b>En ALE FBO & Management nos encargamos de la administración de su aeronave</b></p>
                <p>El equipo necesariamente debe ser incluido al AOC (Air Operator's Certificate) de ALE para que pueda
                    disfrutar de los programas, beneficio, tarifas y expertise con más de 55 años en la industria de
                    aviación.</p>
                <div className={style.needs}>
                    <div className={style.item}>
                        <Matriculas/>
                        <p>Preferentemente las aeronaves deben estar matriculadas como "XA", ya que es la estructura que
                            tiene mayores beneficios fiscales y operacionales.</p>
                    </div>
                    <div className={style.item}>
                        <Calendar/>
                        <p>La inclusión requiere de un periodo aproximado de 12 a 14 semanas.</p>
                    </div>
                    <div className={style.item}>
                        <Juridico/>
                        <p>Durante el proceso, nuestro equipo jurídico y de ingeniería de operaciones proporcionan los
                            avances de cada trámite.</p>
                    </div>
                </div>
            </div>
            <Form align="bottom" options={['FBO: Aircraft Management/Hangar']}/>
            <div className={style.fboImages}>
                <Img className={style.img} fluid={data.foto4.childImageSharp.fluid}/>
            </div>

        </Layout>
    )
}

export const query = graphql`
    query ImagesFBO {
    portada: file(relativePath: {eq: "fbo/foto_portada_2023.jpg"}) {
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto1: file(relativePath: {eq: "fbo/FBO1.jpg"}) {
        publicURL
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto2: file(relativePath: {eq: "fbo/FBO2.jpg"}) {
        publicURL
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto3: file(relativePath: {eq: "fbo/FBO3.jpg"}) {
        publicURL
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    foto4: file(relativePath: {eq: "fbo/FBO4.jpg"}) {
        publicURL
        childImageSharp {
            fluid(maxWidth: 1980) {
                  ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
   
}`;



